import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _29c98d1c = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _0a8de79d = () => interopDefault(import('../pages/cl_payments/index.vue' /* webpackChunkName: "pages/cl_payments/index" */))
const _27653644 = () => interopDefault(import('../pages/client_trends/index.vue' /* webpackChunkName: "pages/client_trends/index" */))
const _55d4840e = () => interopDefault(import('../pages/contract/index.vue' /* webpackChunkName: "pages/contract/index" */))
const _0e844e77 = () => interopDefault(import('../pages/docs/index.vue' /* webpackChunkName: "pages/docs/index" */))
const _7cbba8d7 = () => interopDefault(import('../pages/goal_terms/index.vue' /* webpackChunkName: "pages/goal_terms/index" */))
const _145e0915 = () => interopDefault(import('../pages/lessons/index.vue' /* webpackChunkName: "pages/lessons/index" */))
const _21b63083 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _fa66a354 = () => interopDefault(import('../pages/reserve/index.vue' /* webpackChunkName: "pages/reserve/index" */))
const _11bf6c38 = () => interopDefault(import('../pages/training_events/index.vue' /* webpackChunkName: "pages/training_events/index" */))
const _11fa684a = () => interopDefault(import('../pages/version_up.vue' /* webpackChunkName: "pages/version_up" */))
const _43575d6a = () => interopDefault(import('../pages/login/deputy.vue' /* webpackChunkName: "pages/login/deputy" */))
const _4622cb44 = () => interopDefault(import('../pages/login/forget_id.vue' /* webpackChunkName: "pages/login/forget_id" */))
const _05734598 = () => interopDefault(import('../pages/login/re_password.vue' /* webpackChunkName: "pages/login/re_password" */))
const _8617b9b8 = () => interopDefault(import('../pages/reserve/new.vue' /* webpackChunkName: "pages/reserve/new" */))
const _506f5969 = () => interopDefault(import('../pages/settings/alert.vue' /* webpackChunkName: "pages/settings/alert" */))
const _7db1164c = () => interopDefault(import('../pages/settings/alerts.vue' /* webpackChunkName: "pages/settings/alerts" */))
const _3ea83793 = () => interopDefault(import('../pages/settings/card.vue' /* webpackChunkName: "pages/settings/card" */))
const _25fcfba9 = () => interopDefault(import('../pages/settings/email.vue' /* webpackChunkName: "pages/settings/email" */))
const _2c1c67c4 = () => interopDefault(import('../pages/settings/password.vue' /* webpackChunkName: "pages/settings/password" */))
const _741461f6 = () => interopDefault(import('../pages/settings/profile.vue' /* webpackChunkName: "pages/settings/profile" */))
const _916cf22e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _d7c2ad5a = () => interopDefault(import('../pages/bodyscans/_filter.vue' /* webpackChunkName: "pages/bodyscans/_filter" */))
const _00d5be16 = () => interopDefault(import('../pages/cl_payments/_uid.vue' /* webpackChunkName: "pages/cl_payments/_uid" */))
const _eb25e238 = () => interopDefault(import('../pages/points/_filter.vue' /* webpackChunkName: "pages/points/_filter" */))
const _04b6bc3d = () => interopDefault(import('../pages/reserve/_uid.vue' /* webpackChunkName: "pages/reserve/_uid" */))
const _60b6a9fc = () => interopDefault(import('../pages/t/_campaign_uid.vue' /* webpackChunkName: "pages/t/_campaign_uid" */))
const _c8c4c3fa = () => interopDefault(import('../pages/ticket_sets/_filter.vue' /* webpackChunkName: "pages/ticket_sets/_filter" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart/",
    component: _29c98d1c,
    pathToRegexpOptions: {"strict":true},
    name: "cart"
  }, {
    path: "/cl_payments/",
    component: _0a8de79d,
    pathToRegexpOptions: {"strict":true},
    name: "cl_payments"
  }, {
    path: "/client_trends/",
    component: _27653644,
    pathToRegexpOptions: {"strict":true},
    name: "client_trends"
  }, {
    path: "/contract/",
    component: _55d4840e,
    pathToRegexpOptions: {"strict":true},
    name: "contract"
  }, {
    path: "/docs/",
    component: _0e844e77,
    pathToRegexpOptions: {"strict":true},
    name: "docs"
  }, {
    path: "/goal_terms/",
    component: _7cbba8d7,
    pathToRegexpOptions: {"strict":true},
    name: "goal_terms"
  }, {
    path: "/lessons/",
    component: _145e0915,
    pathToRegexpOptions: {"strict":true},
    name: "lessons"
  }, {
    path: "/login/",
    component: _21b63083,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reserve/",
    component: _fa66a354,
    pathToRegexpOptions: {"strict":true},
    name: "reserve"
  }, {
    path: "/training_events/",
    component: _11bf6c38,
    pathToRegexpOptions: {"strict":true},
    name: "training_events"
  }, {
    path: "/version_up/",
    component: _11fa684a,
    pathToRegexpOptions: {"strict":true},
    name: "version_up"
  }, {
    path: "/login/deputy/",
    component: _43575d6a,
    pathToRegexpOptions: {"strict":true},
    name: "login-deputy"
  }, {
    path: "/login/forget_id/",
    component: _4622cb44,
    pathToRegexpOptions: {"strict":true},
    name: "login-forget_id"
  }, {
    path: "/login/re_password/",
    component: _05734598,
    pathToRegexpOptions: {"strict":true},
    name: "login-re_password"
  }, {
    path: "/reserve/new/",
    component: _8617b9b8,
    pathToRegexpOptions: {"strict":true},
    name: "reserve-new"
  }, {
    path: "/settings/alert/",
    component: _506f5969,
    pathToRegexpOptions: {"strict":true},
    name: "settings-alert"
  }, {
    path: "/settings/alerts/",
    component: _7db1164c,
    pathToRegexpOptions: {"strict":true},
    name: "settings-alerts"
  }, {
    path: "/settings/card/",
    component: _3ea83793,
    pathToRegexpOptions: {"strict":true},
    name: "settings-card"
  }, {
    path: "/settings/email/",
    component: _25fcfba9,
    pathToRegexpOptions: {"strict":true},
    name: "settings-email"
  }, {
    path: "/settings/password/",
    component: _2c1c67c4,
    pathToRegexpOptions: {"strict":true},
    name: "settings-password"
  }, {
    path: "/settings/profile/",
    component: _741461f6,
    pathToRegexpOptions: {"strict":true},
    name: "settings-profile"
  }, {
    path: "/",
    component: _916cf22e,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/bodyscans/:filter?/",
    component: _d7c2ad5a,
    pathToRegexpOptions: {"strict":true},
    name: "bodyscans-filter"
  }, {
    path: "/cl_payments/:uid/",
    component: _00d5be16,
    pathToRegexpOptions: {"strict":true},
    name: "cl_payments-uid"
  }, {
    path: "/points/:filter?/",
    component: _eb25e238,
    pathToRegexpOptions: {"strict":true},
    name: "points-filter"
  }, {
    path: "/reserve/:uid/",
    component: _04b6bc3d,
    pathToRegexpOptions: {"strict":true},
    name: "reserve-uid"
  }, {
    path: "/t/:campaign_uid?/",
    component: _60b6a9fc,
    pathToRegexpOptions: {"strict":true},
    name: "t-campaign_uid"
  }, {
    path: "/ticket_sets/:filter?/",
    component: _c8c4c3fa,
    pathToRegexpOptions: {"strict":true},
    name: "ticket_sets-filter"
  }, {
    path: "/_t/*",
    redirect: "/t/*"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
